 <template>
  <div class="mt10">
    <div class="mt10 mb-3">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/hospital' }">{{ $t('message.hospital') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('message.defects') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="defects__blocks h-100 bg-white">
       <table class="table table-bordered  table-striped table-hover" v-loading="loadingData">
            <thead>
                <tr class="text-center" >
                    <th><b> {{ $t('message.drug') }}</b></th>
                    <th><b> {{ $t('message.date_0') }}</b></th>
                    <th><b> {{ $t('message.comment') }}</b></th>
                    <th><b> {{ $t('message.settings') }}</b></th>
                </tr>
            </thead>
            <transition-group name="flip-list" tag="tbody">
                <tr v-for="date in list" :key="date.id" class="cursor-pointer">
                      <td width="250px" class="text-center">
                            <div class="nome__medicine" v-for="i in date.dailyTreatment.products" :key="i.id + 'products'">{{ i.name }}<br></div>
                            <div class="nome__medicine" v-for="v in date.dailyTreatment.dailyTreatmentNames" :key="v.id + '-dtName'">{{ v.name }}<br></div>
                      </td>
                      <td width="150px" class="text-center">{{ date.day }}</td>
                      <td>{{ date.comment }}</td>
                      <td width="200px" class="text-center"> 
                          <template v-if="date.dailyTreatment.patientHistory.hospitalPatient">
                            <router-link :to="{ name: 'dailyTreatment',  params:{id:date.dailyTreatment.patientHistory.hospitalPatient.id}}">
                                <el-button type="success" size="small" icon="el-icon-refresh" plain round>{{ $t('message.replacement') }}</el-button>
                            </router-link>
                          </template>
                      </td>
                </tr>
            </transition-group>
        </table>
      <!-- end itme-defects -->
      <!-- end itme-defects -->

    </div>
  </div>
</template>

  <script>
 import { mapGetters, mapActions } from "vuex";
    import list from "@/utils/mixins/list";
    import drawer from "@/utils/mixins/drawer";
    export default {
        mixins:[list ,drawer],
        name: "defects",
        components:{
        },
        data(){
            return {
                loadingData: false,
                selectedModel: {},
                drawer: {
                    update: {
                        name: "update",
                        status: false,
                        component: 'componentDrawerUpdate'
                    }
                },
            }
        },
        computed:{
            ...mapGetters({
                list: 'dailyTreatments/allDefect',
                columns: "dailyTreatments/columns",
                pagination: "dailyTreatments/pagination",
                filter: "dailyTreatments/filter",
                sort: "dailyTreatments/sort",
            }),
            actions: function() {
              return ['edit', 'delete'];
            }
        },
         async mounted(){
        },
        methods: {
            ...mapActions({
                updateList: 'dailyTreatments/allDefect',
                updateSort: "dailyTreatments/updateSort",
                updateFilter: "dailyTreatments/updateFilter",
                updateColumn: "dailyTreatments/updateColumn",
                updatePagination: "dailyTreatments/updatePagination",
                editModel: 'dailyTreatments/show',
                empty: 'dailyTreatments/empty',
                delete: 'dailyTreatments/destroy',
                refreshData: 'dailyTreatments/refreshData',
            }),
            fetchData() {
                const query = { ...this.filter, ...this.pagination, ...this.sort };
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
            refresh() {
                this.refreshData()
                    .then(res => {
                        this.filterForm = JSON.parse( JSON.stringify( this.filter ));
                    })
                    .catch(err => {

                    })
            },
            edit(model){
                this.selectedModel = model;
                this.drawer.update.status = true;
            },
            async show(model){
                await this.showModel(model.id)
                    .then(res => {
                        this.drawerShow = true;
                    })
                    .catch(err => {

                    })
            },
            destroy(model){
                this.delete(model.id)
                    .then(res => {
                        this.$alert(res);
                        this.fetchData()
                    })
                    .catch(err => {
                       console.log(err)
                    })
            },
            emptyModel(){
                this.empty()
            },
        }
};
</script>
